<template>
    <div>
      <div class="center" v-if="showLoading">
          <lottie :options="defaultOptionsSplashScreen" :height="300" :width="300" v-on:animCreated="handleAnimationSplashScreen"/>
      </div>
       <div class="home" v-else>
          <div class="gl-header">
            <nav class="navbar navbar-fixed-top">
              <div class="container">
                <div id="site-logo" class="navbar-header" style="width:100%;margin-left: 0px;"><!-- align-items: center;-->
                  <div class="-user-balance js-user-balance f-sm-6 f-7 " style="padding-top:5px; flex-direction: row;display: flex;">
                    <div style="display: flex; flex: 1;">
                      <a class="navbar-brand" @click="goto('home')" title="back to home page">
                      <div style="display: flex; align-items: center;height:10px;justify-content:flex-end">
                        <span v-if="this.web && this.web.name" style="font-weight: 800; color: #fff; padding-top: 40px; font-size: 20px;font-family: Kanit,Helvetica Neue,Helvetica,Arial,sans-serif;" class="p2">{{this.web.name.toUpperCase()}}</span>
                      </div>
                      </a>
                    </div>
                    <div>
                    <div style="display: flex; align-items: center;justify-content:flex-end">
                      <div style="background: #e7dcef; height: 32px !important; align-items: center; display: flex; border-radius: 20px;">
                        <img style="width: 30px; height: 30px" src="../assets/images/ez-slot-ic-coin.png" alt="customer image">
                        <span style="font-weight: 500; color: rgb(71, 44, 89); padding: 4px 20px 4px 6px; font-size: 14px;">{{point}}</span>
                      </div>
                    </div>
                    <div style="display: flex; align-items: center;height:10px;padding-top:15px;justify-content:flex-end">
                      <span v-if="this.code" style="font-weight: 500; color: #fff; padding: 4px 10px 4px 6px; font-size: 14px;font-family: Kanit,Helvetica Neue,Helvetica,Arial,sans-serif;" class="p2">{{this.code.toUpperCase()}}</span>
                    </div>
                    </div>
                    <div style="padding-left:15px;margin-top:-5px;">
                      <img src="../assets/images/logout.png" style="height:40px;cursor:pointer" @click="logout">
                    </div>
                  </div>
                </div>
              </div>
            </nav>
              </div>
          <main class="mqbg">
              <div class="gl-content">
                  <!-- start top bookmark -->
                  <div id="here_top">&nbsp;</div>
                  <!-- end top bookmark -->
              </div>
              <!-- start content -->
              <div id="contact_gl" class="section" :style="{ 'background-image': `url(${pictureUrl})`}">
                  <div class="container">
                      <div class="spin">
                          <div style="position: absolute; bottom: 20px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;">
                              <div style="display: flex; flex: 1; align-items: center; justify-content: center;">
                                  <div style="border-radius: 50px; margin-left: 0px; background: #2f2f2f; align-items: center; justify-content: center; display: flex; padding-left: 16px; padding-right: 16px; padding-top: 9px; padding-bottom: 9px;">
                                      <span class="glow" ref="myBtn">
                                      {{loadingtext}}
                                      </span>
                                  </div>
                              </div>
                          </div>
                          <div
        style="position: absolute; top: 60px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;"
      >
        <div
          style="position: absolute; top: 0px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;"
        >
          <lottie
            :options="defaultOptionsCut"
            :height="250"
            :width="250"
            v-on:animCreated="handleAnimationCut"
          />
        </div>
        <div
          style="position: absolute; top: 0px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;"
        >
          <lottie
            :options="defaultOptionsPaper"
            :height="250"
            :width="250"
            v-on:animCreated="handleAnimationPaper"
          />
        </div>
        <div
          style="position: absolute; top: 0px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;"
        >
          <lottie
            :options="defaultOptionsHammer"
            :height="250"
            :width="250"
            v-on:animCreated="handleAnimationHammer"
          />
        </div>
      </div>
      <div
        style="position: absolute; top: 300px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;"
      >
        <div
          style="position: absolute; top: 0px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;"
        >
          <lottie
            :options="defaultOptions2Cut"
            :height="250"
            :width="250"
            v-on:animCreated="handleAnimation2Cut"
          />
        </div>
        <div
          style="position: absolute; top: 0px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;"
        >
          <lottie
            :options="defaultOptions2Paper"
            :height="250"
            :width="250"
            v-on:animCreated="handleAnimation2Paper"
          />
        </div>
        <div
          style="position: absolute; top: 0px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;"
        >
          <lottie
            :options="defaultOptions2Hammer"
            :height="250"
            :width="250"
            v-on:animCreated="handleAnimation2Hammer"
          />
        </div>
      </div>
      <div
        :style="{
          position: 'absolute',
          top: '200px',
          'align-items': 'center',
          'justify-content': 'center',
          display: 'flex',
          left: '0',
          right: '0',
          display: showResult ? 'block' : 'none'
        }"
      >
        <div
          :style="{
            position: 'absolute',
            top: '0px',
            'align-items': 'center',
            'justify-content': 'center',
            display: win,
            left: '0',
            right: '0'
          }"
        >
          <!-- <lottie :options="defaultOptionsWin" :height="550" :width="550"  v-on:animCreated="handleAnimationWin" /> -->
          <img src="../assets/images/win.png" />
        </div>
        <div
          :style="{
            position: 'absolute',
            top: '0px',
            'align-items': 'center',
            'justify-content': 'center',
            display: lose,
            left: '0',
            right: '0'
          }"
        >
          <!-- <lottie :options="defaultOptionsLose" :height="550" :width="550"  v-on:animCreated="handleAnimationLose" /> -->
          <img src="../assets/images/lose.png" />
        </div>
        <div
          :style="{
            position: 'absolute',
            top: '0px',
            'align-items': 'center',
            'justify-content': 'center',
            display: draw,
            left: '0',
            right: '0'
          }"
        >
          <!-- <lottie :options="defaultOptionsDraw" :height="550" :width="550"  v-on:animCreated="handleAnimationDraw" /> -->
          <img src="../assets/images/draw.png" />
        </div>
      </div>
      <div
        style="position: absolute; bottom: 80px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;"
      >
        <div
          style="display: flex; flex: 1; align-items: center; justify-content: center;"
        >
          <div style="border-style: dashed;">
            <img
              src="../assets/images/1cut.png"
              width="124"
              @click="select('cut')"
            />
          </div>
          <div style="border-style: dashed;">
            <img
              src="../assets/images/2paper.png"
              width="124"
              @click="select('paper')"
            />
          </div>
          <div style="border-style: dashed;">
            <img
              src="../assets/images/3hammer.png"
              width="124"
              @click="select('hammer')"
            />
          </div>
        </div>
      </div>
                      </div>
                  </div>
              </div>
            </main>
  <!--
          <div class="x-button-actions" id="account-actions-mobile" style="position: fixed; left: 0; right: 0; bottom: 0;">
                  <div class="d-flex">
                      <div class="-btn-container -withdraw-container">
                          <a href="javascript:void(0);" data-toggle="modal" data-target="#withdrawModal" class="btn btn-lg btn-plain-primary  js-account-approve-aware">
                              <img src="../assets/images/ez-slot-ic_withdraw_mobile.png" alt="EZ Slot ถอนเงิน" class="-icon img-fluid">
                              <div class="-typo">ถอนเงิน</div>
                          </a>
                      </div>
                      <div class="-btn-container -lobby-container">
                          <a href="/%E0%B8%AA%E0%B8%A5%E0%B9%87%E0%B8%AD%E0%B8%95" class="btn btn-lg btn-plain-lobby">
                              <div class="-img-container">
                                  <img src="../assets/images/ez-slot-ic_lobby_mobile.png" class="-icon img-fluid" alt="EZ Slot เข้าเล่น Lobby">
                                  <img src="../assets/images/ez-slot-ic_lobby_mobile_play.png" class="-icon-play img-fluid" alt="EZ Slot เข้าเล่น Lobby">
                              </div>
                              <div class="-typo">หน้าเกมส์</div>
                          </a>
                      </div>
                      <div class="-btn-container -deposit-container">
                          <a href="javascript:void(0);" data-toggle="modal" data-target="#depositChoosePromotionModal" class="btn btn-lg btn-plain-secondary js-account-approve-aware">
                              <img src="../assets/images/ez-slot-ic_deposit_mobile.png" alt="EZ Slot ฝากเงิน" class="-icon img-fluid">
                              <div class="-typo">ฝากเงิน</div>
                          </a>
                      </div>
                  </div>
              </div>
  -->
  
          <div style="position: fixed; left: 0; right: 0; bottom: 0; display: flex; z-index: 99; align-items: flex-end;" >
            <div style="flex: 1; background: linear-gradient( 180deg,#c1e9fe 10%,#56aff0); border-top-left-radius: 30px; display: flex; justify-content: center; height: 58px; box-shadow: 0 0 15px 0 rgb(0 0 0 / 30%); flex-direction: column; align-items: center;cursor:pointer;" @click="goto('website')">
              <img :src="logoLeft" alt="ทางเข้าเล่น" style="width: 60px; height: 58px; margin-top: -35px;margin-bottom: 8px">
              <div style="font-weight: 800; color: #fff; margin-top: -6px;font-family: Kanit,Helvetica Neue,Helvetica,Arial,sans-serif;">ทางเข้าเล่น</div>
            </div>
            <div style="flex: 1; background: linear-gradient( 180deg,#fedd5b 10%,#fe8c06); border-top-right-radius: 25px; border-top-left-radius: 25px; box-shadow: 0 0 15px 0 rgb(0 0 0 / 30%);z-index: 1;height: 80px; display: flex; flex-direction: column; align-items: center; justify-content: flex-end;cursor:pointer;" @click="goto('home')">
              <div>
                  <img class="animate__animated animate__pulse animate__infinite" src="../assets/images/game-console.png" style="width: 105px; height: 105px; margin-bottom: 10px;" alt="เข้าเล่น Lobby">
              </div>
              <!-- <div style="font-weight: 800; color: #fff; margin-top: -13px;font-family: Kanit,Helvetica Neue,Helvetica,Arial,sans-serif;" class="p2">หน้าเกมส์</div> -->
            </div>
            <div style="flex: 1; background: linear-gradient( 180deg,#c1e9fe 10%,#56aff0); border-top-right-radius: 30px; display: flex; justify-content: center; height: 58px; justify-content: center; box-shadow: 0 0 15px 0 rgb(0 0 0 / 30%); flex-direction: column; align-items: center;cursor:pointer;" @click="goto('contact')">
              <img :src="logoRight" alt="ติดต่อ" style="width: 60px; height: 58px; margin-top: -35px;margin-bottom: 8px;margin-left: 18px;">
               <div style="font-weight: 800; color: #fff; margin-top: -6px;font-family: Kanit,Helvetica Neue,Helvetica,Arial,sans-serif;">ติดต่อ</div>
            </div>
          </div>
        </div>
    </div>
  </template>
  <script>
  import '../assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css'
  import '../assets/fonts/iconic/css/material-design-iconic-font.min.css'
  import '../assets/css/main.css'
  import '../assets/css/util.css'
  import firebase, { database } from 'firebase/app'
  import 'firebase/firestore'
  import 'firebase/database'
  import 'firebase/auth'
  import _ from 'lodash'
  // const axios = require("axios").default;
  import moment from 'moment-timezone'
  import router from './../router'
  import Lottie from './lottie.vue'
  import * as animationData1 from "../assets/1cut.json";
import * as animationData2 from "../assets/2paper.json";
import * as animationData3 from "../assets/3hammer.json";
  import * as animateSantaWalk from '../assets/youcandoit.json'
  require('vue2-animate/dist/vue2-animate.min.css')
  const axios = require('axios').default
  const audio2 = new Audio(require('../assets/chinese.mp3'))
  // @ is an alias to /src
  export default {
    name: 'Gift',
    created () {
        const animage = animationData1.default;
        this.defaultOptionsCut = {
        animationData: animage,
        autoplay: false,
        loop: false
        };
        this.defaultOptions2Cut = {
        animationData: animage,
        autoplay: false,
        loop: false
        };

        const animage2 = animationData2.default;
        this.defaultOptionsPaper = {
        animationData: animage2,
        autoplay: false,
        loop: false
        };
        this.defaultOptions2Paper = {
        animationData: animage2,
        autoplay: false,
        loop: false
        };

        const animage3 = animationData3.default;
        this.defaultOptionsHammer = {
        animationData: animage3,
        autoplay: false,
        loop: false
        };
        this.defaultOptions2Hammer = {
        animationData: animage3,
        autoplay: false,
        loop: false
        };
        document.body.style.backgroundColor = '#00c7ff'
        const animateSplashScreen = animateSantaWalk.default
        this.defaultOptionsSplashScreen = { animationData: animateSplashScreen, autoplay: false, loop: true }
    },
    mounted () {
      if (!firebase.auth().currentUser) {
        router.replace('/login')
      } else {
        this.getUserId()
        audio2.volume = 0.6
        audio2.play()
        this.animSplashScreen.play()
        setTimeout(() => {
          this.showLoading = false
          this.animSplashScreen.stop()
        }, 3500)
      }
      this.getGames()
    },
    data () {
      return {
        win: "none",
        lose: "none",
        draw: "none",
        showResult: false,
        gameKey: 'paoyingchup',
        logoLeft:'',
        logoRight:'',
        showLoading: true,
        pictureUrl: '',
        code: '',
        user: [],
        games_web: [],
        search: '',
        loading: false,
        error: false,
        web: [],
        message: 'Invalid user name or password!',
        point: 0,
        favorites: [],
        animationInfinite: {
          classes: 'fadeIn',
          duration: 5000,
          iteration: 'infinite'
        },
        permission: [],
        loadingtext: '',
        defaultOptions: null,
        defaultOptions2: null,
        defaultOptionsSplashScreen: null,
        openGiftData: false,
        canOpen: false,
        percent:[],
        sections:[],
      }
    },
    components: {
      lottie: Lottie
    },
    methods: {
      logout(){
          this.$confirm(
            {
              message: `ต้องการล็อกเอาท์จากระบบ`,
              button: {
                yes: 'Yes',
                no: 'No'
              },
              callback: confirm => {
                if (confirm) {
                  firebase.auth().signOut().then(()=>{
                    router.replace('/login')
                  })
                }
              }
            }
          );
      },
      randomIntFromInterval(min, max) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min)
      },
      async openGift () {
        if (this.canOpen && this.user && this.gameKey) {
          this.canOpen = false;
          this.anim.play()
          setTimeout(() => {
              const r = Math.floor(Math.random() * 10) + 1;
              const item = this.games_web['spin'+r];
              const type = this.games_web['spin'+r+'color'];
              if(type === 'credit'){
                  firebase.database().ref(`users/${this.$projectId}/${this.user.key}/games`).update({
                      [`${this.gameKey}`]: firebase.database.ServerValue.increment(-1)
                  })
              }else{
                  const update = {}
                  if(type === 'point'){
                      this.point = this.point + Number(item)
                      update[`point`] = firebase.database.ServerValue.increment(Number(item))
                  }
                  update[`games/${this.gameKey}`] = firebase.database.ServerValue.increment(-1)
                  firebase.database().ref(`users/${this.$projectId}/${this.user.key}`).update(update)
              }
              //let status = (this.games_web.type === 'credit')?false:true;
              // if(!isnum){
              //     //สูตร 2 ชั่วโมง
              //     const n = item.split(' ');
              //     const games = {};
              //     games['hackslot'] = {};
              //     games['hackslot'].value = Number(n[1]);
              //     games['hackslot'].expiredate = Number(moment().tz("Asia/Bangkok").add(n[1],'hours').format("YYYYMMDDHHmmss"));
              //     games['hackslot'].expiredate2 = moment().tz("Asia/Bangkok").add(Number(n[1]),'hours').format("YYYY-MM-DD HH:mm:ss");
              //     firebase.database().ref(`users/${this.$projectId}/`+this.user.key+'/games').update({
              //     hackslot:games['hackslot'],
              //     })
              // }
              firebase.firestore().collection('transections').add({
                  code: this.user.code,
                  mobile: this.user.mobile,
                  createdate: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss'),
                  game: this.gameKey,
                  game_name: this.games_web.name,
                  qty: item,
                  type,
                  status: (type === 'point')?true:false,
                  web: this.$projectId
              }).then(()=>{
                  const showText = `${item} ${type}`;
                  this.$confirm(
                      {
                      message: `ปังปุรีเย่!! คุณได้รับ ${showText}`,
                      button: {
                          yes: 'OK'
                      },
                      callback: async (confirm) => {
                          if (confirm) {
                              audio2.pause();
                              router.replace('/games')
                          }
                      }
                      }
                  )
              })
  
  
              // const qty = this.randomIntFromInterval(this.games_web.from, this.games_web.to)
              // if(this.games_web.type === 'credit'){
              //     firebase.database().ref(`users/${this.$projectId}/${this.user.key}/games`).update({
              //         [`${this.gameKey}`]: firebase.database.ServerValue.increment(-1)
              //     })
              // }else{
              //     this.point = this.point+qty;
              //     const update = {};
              //     update[`games/${this.gameKey}`] = firebase.database.ServerValue.increment(-1)
              //     update[`point`] = firebase.database.ServerValue.increment(qty)
              //     firebase.database().ref(`users/${this.$projectId}/${this.user.key}`).update(update)
              // }
          }, 5000);
        }
      },
      select(select) {
        if (this.canOpen && this.user && this.gameKey) {
        this.$confirm({
          message: `ยืนยันการเลือก${
            select === "cut" ? "กรรไกร" : select === "paper" ? "กระดาษ" : "ค้อน"
          }?`,
          button: {
            yes: "OK",
            no: "Cancel"
          },
          callback: async confirm => {
            if (confirm) {
              this.canOpen = false;
              const com = Math.floor(Math.random() * 3);
              let select2 = "";
              if (com === 0) {
                select2 = "cut";
                this.animPaper.hide();
                this.animHammer.hide();
                this.animCut.play();
              } else if (com === 1) {
                select2 = "paper";
                this.animPaper.play();
                this.animHammer.hide();
                this.animCut.hide();
              } else {
                select2 = "hammer";
                this.animPaper.hide();
                this.animHammer.play();
                this.animCut.hide();
              }

              if (select === "cut") {
                this.animCut2.play();
                this.animPaper2.hide();
                this.animHammer2.hide();
              } else if (select === "paper") {
                this.animPaper2.play();
                this.animCut2.hide();
                this.animHammer2.hide();
              } else {
                this.animHammer2.play();
                this.animCut2.hide();
                this.animPaper2.hide();
              }
              console.log(select2 + "==" + select);
              if (select2 === select) {
                firebase.database().ref(`users/${this.$projectId}/${this.user.key}/games`).update({
                    [`${this.gameKey}`]: firebase.database.ServerValue.increment(-1)
                })
                setTimeout(() => {
                  this.showResult = true;
                  this.win = "none";
                  this.lose = "none";
                  this.draw = "flex";
                  setTimeout(() => {
                    this.sendMessageLine(`คุณเสมอเสียใจด้วยนะจ๊ะ 😢`);
                  }, 2000);
                  // this.animDraw.play()
                  // this.animWin.hide()
                  // this.animLose.hide()
                }, 3000);
              } else if (
                (select2 === "cut" && select === "paper") ||
                (select2 === "paper" && select === "hammer") ||
                (select2 === "hammer" && select === "cut")
              ) {
                console.log("lose");
                setTimeout(() => {
                  this.showResult = true;
                  this.win = "none";
                  this.lose = "flex";
                  this.draw = "none";
                  firebase.database().ref(`users/${this.$projectId}/${this.user.key}/games`).update({
                    [`${this.gameKey}`]: firebase.database.ServerValue.increment(-1)
                  })
                  setTimeout(() => {
                    this.sendMessageLine(`คุณแพ้เสียใจด้วยนะจ๊ะ 😭`);
                  }, 2000);
                  // this.animLose.play()
                  // this.animWin.hide()
                  // this.animDraw.hide()
                }, 3000);
              } else {
                const item = 50;
                setTimeout(() => {
                  this.showResult = true;
                  const item = this.games_web['from'];
                  const type = this.games_web['type'];
                  this.win = "flex";
                  this.lose = "none";
                  this.draw = "none";
                  // this.animWin.play()
                  // this.animDraw.hide()
                  // this.animLose.hide()
                    if(type === 'credit'){
                        firebase.database().ref(`users/${this.$projectId}/${this.user.key}/games`).update({
                            [`${this.gameKey}`]: firebase.database.ServerValue.increment(-1)
                        })
                    }else{
                        const update = {}
                        if(type === 'point'){
                            this.point = this.point + Number(item)
                            update[`point`] = firebase.database.ServerValue.increment(Number(item))
                        }
                        update[`games/${this.gameKey}`] = firebase.database.ServerValue.increment(-1)
                        firebase.database().ref(`users/${this.$projectId}/${this.user.key}`).update(update)
                   }
                   firebase.firestore().collection('transections').add({
                        code: this.user.code,
                        mobile: this.user.mobile,
                        createdate: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss'),
                        game: this.gameKey,
                        game_name: this.games_web.name,
                        qty: item,
                        type,
                        status: (type === 'point')?true:false,
                        web: this.$projectId
                    }).then(()=>{
                        const showText = `${item} ${type}`;
                        this.$confirm(
                            {
                            message: `ปังปุรีเย่!! คุณได้รับ ${showText}`,
                            button: {
                                yes: 'OK'
                            },
                            callback: async (confirm) => {
                                if (confirm) {
                                    audio2.pause();
                                    router.replace('/games')
                                }
                            }
                            }
                        )
                    })
                  setTimeout(() => {
                    this.$confirm({
                      message: `✌️ ปังปุรีเย่!! คุณได้รับ ${item} เหรียญ😘😘`,
                      button: {
                        yes: "OK"
                      },
                      callback: async confirm => {
                        if (confirm) {
                        }
                      }
                    });
                  }, 2000);
                }, 3000);
              }
            }
          }
        });
      }
    },
        handleAnimationSplashScreen: function (anim) {
            this.animSplashScreen = anim
        },
        handleAnimation2: function (anim2) {
            this.anim2 = anim2
        },
        handleAnimationCut: function(anim) {
            this.animCut = anim;
        },
        handleAnimationPaper: function(anim) {
            this.animPaper = anim;
        },
        handleAnimationHammer: function(anim) {
            this.animHammer = anim;
        },
        handleAnimation2Cut: function(anim) {
         this.animCut2 = anim;
        },
        handleAnimation2Paper: function(anim) {
            this.animPaper2 = anim;
        },
        handleAnimation2Hammer: function(anim) {
         this.animHammer2 = anim;
        },
      async getGames () {
        this.games_web = (await firebase.database().ref(`/games_web/${this.$projectId}/${this.gameKey}`).once('value')).val()
        firebase.database().ref(`/games/${this.gameKey}`).once('value').then((snapshot) => {
          if (!snapshot.val().status || !this.games_web.status) {
              audio2.pause();
              router.replace('/games')
          }
        })
      },
      async getUserId () {
        this.web = (await firebase.database().ref(`/web/${this.$projectId}`).once('value')).val()
        if (this.web) {
          if(this.web.login_image){
            let width = screen.width;
            if(width <= 480 && this.web.login_image_mobile)
            this.pictureUrl = this.web.login_image_mobile;
            else
            this.pictureUrl = this.web.login_image;
          }else
            this.pictureUrl = require('../assets/images/2092839.jpg')
          if(this.web.img_left)
            this.logoLeft = this.web.img_left
          else
            this.logoLeft = require('../assets/images/casino.png')
          if(this.web.img_right)
            this.logoRight = this.web.img_right
          else
            this.logoRight = require('../assets/images/contact.png')
          const email = firebase.auth().currentUser.email.split('@')
          const users = (await firebase.database().ref(`/users/${this.$projectId}`).orderByChild('mobile').equalTo(email[0]).once('value')).val()
          this.user = users[Object.keys(users)[0]]
          this.user.key = Object.keys(users)[0]
          if (this.user) {
            this.point = this.user.point
            this.code = this.user.code
            this.permission = this.user.games[this.gameKey]
            if (this.permission > 0) {
              this.canOpen = true 
            }else{ 
              this.canOpen = false
            }
          } else {
            router.replace('/login')
          }
          var styleElem = document.head.appendChild(document.createElement('style'))
          styleElem.innerHTML = `#contact_gl:before {background: ${this.web.login_color};}`
        } else {
          router.replace('/login')
        }
      },
      checkCssBox (game) {
        if (!game.status) {
          return 'box-shadow: 0 0 12px 5px #cccccc;border: 2px solid #cccccc;'
        }
      },
      checkCss (game) {
        if (!this.permission || !this.permission[game.key]) {
          return 'filter: grayscale(100%);'
        }
        // const index = _.findIndex(this.favorites, function(o) { return o == code; });
        // if (index>-1) {
        //   return '';
        // } else {
        //   return 'filter: grayscale(100%);';
        // }
      },
      favorite (gameCode) {
        const index = _.findIndex(this.favorites, function (o) { return o == gameCode })
        if (this.userId) {
          if (index > -1) {
            firebase.database().ref('favorites/' + this.userId + '/' + gameCode).remove()
            this.favorites.splice(index, 1)
          } else {
            firebase.database().ref('favorites/' + this.userId + '/' + gameCode).push({
              gameCode
            })
            this.favorites.push(gameCode)
          }
        }
      },
      goto (type) {
          audio2.pause();
        if (type === 'home') {
          // window.scrollTo({
          //   top: 0,
          //   left: 0,
          //   behavior: 'smooth'
          // });
          this.$router.push('/games').catch(() => {})
        } else if (type === 'website') {
          window.location = this.web.link
          // this.gotoline()
        } else if (type === 'contact') {
          window.location = this.web.link_line
          // this.gotoline()
        }
      },
      getSpinClass () {
        if (!this.loading) { return 'fas fa-sync-alt f-9' } else { return 'fas fa-sync-alt fa-spin f-9' }
      },
  
      numberWithCommas (x) {
        return Number(x)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      openGame (game) {
        if (this.permission[game.key]) {
          if (game.key === 'gift') {
            this.$router.push('/gift').catch(() => {})
          }
        }
      }
    }
  }
  </script>
  <style scoped>
  .center {
      position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  #contact_gl {
    min-height: 600px;
    color: #333;
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
      /*background: -moz-radial-gradient(center, ellipse cover, #f7f7f7 0%, #d1d1d1 100%);
      background: -webkit-radial-gradient(center, ellipse cover, #f7f7f7 0%,#d1d1d1 100%);
      background: radial-gradient(ellipse at center, #f7f7f7 0%,#d1d1d1 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f7f7', endColorstr='#d1d1d1',GradientType=1 );*/
  }
  #contact_gl::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.9;
  }
  .boxlogin {
      background: #fff;
      overflow: hidden;
      width: 100%;
      height: 100%;
      border-radius: 2px;
      box-shadow: 0 0 40px #000;
      color: #8b8c8d;
      font-size: 0;
  }
  .boxlogin .box-part {
      display: inline-block;
      position: relative;
      vertical-align: top;
      height: 100%;
  }
  .boxlogin, .box .box-part {
      box-sizing: border-box;
  }
  .boxlogin .partition {
      width: 100%;
      height: 100%;
  }
  .boxlogin .partition .partition-title {
      box-sizing: border-box;
      padding: 30px;
      width: 100%;
      text-align: center;
      letter-spacing: 1px;
      font-size: 20px;
      font-weight: 300;
  }
  .boxlogin .partition .partition-form {
      padding: 0 20px;
      box-sizing: border-box;
  }
  .boxlogin .button-set {
      margin-bottom: 8px;
  }
  .boxlogin .github-btn {
      border-color: #dba226;
      color: #dba226;
  }
  .boxlogin .large-btn {
      width: 100%;
      background: #fff;
  }
  .boxlogin button {
      background: #fff;
      border-radius: 4px;
      box-sizing: border-box;
      padding: 10px;
      letter-spacing: 1px;
      font-family: Open Sans,sans-serif;
      font-weight: 400;
      min-width: 140px;
      margin-top: 8px;
      color: #8b8c8d;
      cursor: pointer;
      border: 1px solid #dddedf;
      text-transform: uppercase;
      transition: all .1s;
      font-size: 10px;
      outline: none;
  }
   .p2 {
       letter-spacing: 0;
       text-shadow: 0 0 80px rgba(255, 255, 255, .5);
      /* Clip Background Image */
       background: url(https://i.ibb.co/RDTnNrT/animated-text-fill.png) repeat-y;
       -webkit-background-clip: text;
       background-clip: text;
      /* Animate Background Image */
       -webkit-text-fill-color: transparent;
       -webkit-animation: aitf 80s linear infinite;
      /* Activate hardware acceleration for smoother animations */
       -webkit-transform: translate3d(0, 0, 0);
       -webkit-backface-visibility: hidden;
  }
  /* Animate Background Image */
   @-webkit-keyframes aitf {
       0% {
           background-position: 0% 50%;
      }
       100% {
           background-position: 100% 50%;
      }
  }
  </style>
  