<template>
  <div>
     <div class="home">
        <div class="gl-header">
          <nav class="navbar navbar-fixed-top">
            <div class="container">
              <div id="site-logo" class="navbar-header" style="width:100%;margin-left: 0px;"><!-- align-items: center;-->
                <div class="-user-balance js-user-balance f-sm-6 f-7 " style="padding-top:5px; flex-direction: row;display: flex;">
                  <div style="display: flex; flex: 1;">
                    <a class="navbar-brand" @click="goto('home')" title="back to home page">
                    <div style="display: flex; align-items: center;height:10px;justify-content:flex-end">
                      <span v-if="this.web && this.web.name" style="font-weight: 800; color: #fff; padding-top: 40px; font-size: 20px;font-family: Kanit,Helvetica Neue,Helvetica,Arial,sans-serif;" class="p2">{{this.web.name.toUpperCase()}}</span>
                    </div>
                    </a>
                  </div>
                  <div>
                  <div style="display: flex; align-items: center;justify-content:flex-end">
                    <div style="background: #e7dcef; height: 32px !important; align-items: center; display: flex; border-radius: 20px;">
                      <img style="width: 30px; height: 30px" src="../assets/images/ez-slot-ic-coin.png" alt="customer image">
                      <span style="font-weight: 500; color: rgb(71, 44, 89); padding: 4px 20px 4px 6px; font-size: 14px;">{{point}}</span>
                    </div>
                  </div>
                  <div style="display: flex; align-items: center;height:10px;padding-top:15px;justify-content:flex-end">
                    <span v-if="this.code" style="font-weight: 500; color: #fff; padding: 4px 10px 4px 6px; font-size: 14px;font-family: Kanit,Helvetica Neue,Helvetica,Arial,sans-serif;" class="p2">{{this.code.toUpperCase()}}</span>
                  </div>
                  </div>
                  <div style="padding-left:15px;margin-top:-5px;">
                    <img src="../assets/images/logout.png" style="height:40px;cursor:pointer" @click="logout">
                  </div>
                </div>
              </div>
            </div>
          </nav>
		    </div>
        <main class="mqbg">
            <div class="gl-content">
                <!-- start top bookmark -->
                <div id="here_top">&nbsp;</div>
                <!-- end top bookmark -->
            </div>
			<!-- start content -->
            <div id="contact_gl" class="section" :style="{ 'background-image': `url(${pictureUrl})`}">
                    <div class="flex-fill" style="padding-top:60px;">
                        <tabs
                            :tabs="tabs"
                            :currentTab="currentTab"
                            :wrapper-class="'default-tabs'"
                            :tab-class="'default-tabs__item'"
                            :tab-active-class="'default-tabs__item_active'"
                            :line-class="'default-tabs__active-line'"
                            @onClick="handleClick"
                            />
                        <div style="margin-bottom: 78px;" v-if="currentTab=='tab1'">
                            <div v-for="(items,index) in rewards_credit" :key="index" style="margin-left: 16px; margin-right: 16px; margin-top: 16px; margin-bottom: 32px; display: flex; flex: 1; flex-direction: row; justify-content: center;">
                                <div v-for="(item,index2) in items" v-bind:key="index+'-'+index2" style="margin-right: 8px; flex: 1;">
                                    <div style="background: #141313;text-align: center;" >
                                        <img :src="item.image" style="object-fit: cover; border-top-left-radius: 7px; border-top-right-radius: 7px; border-bottom-left-radius: 7px; border-bottom-right-radius: 7px;max-width: 100%; height: auto;">
                                    </div>
                                    <div style="cursor:pointer" :class="(point>=item.point)?`canredeem`:`cantredeem`" @click="redeem2(item)">
                                        <span style="font-size: 14px; font-weight: bold; color: #fff;">
                                        <img src="../assets/images/coin1.png" width="20">
                                        แลก {{numberWithCommas(item.point)}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="margin-bottom: 78px;" v-else>
                            <div v-for="(items,index) in rewards_reward" :key="index"  style="margin-left: 16px; margin-right: 16px; margin-top: 16px; margin-bottom: 32px; display: flex; flex: 1; flex-direction: row; justify-content: center;">
                                <div v-for="(item,index2) in items" v-bind:key="index+'-'+index2" style="margin-right: 8px; flex: 1;">
                                    <div style="background: #141313;text-align: center;" >
                                    <img :src="item.image" style="object-fit: cover; border-top-left-radius: 7px; border-top-right-radius: 7px; border-bottom-left-radius: 7px; border-bottom-right-radius: 7px;max-width: 100%; height: auto;">
                                    </div>
                                    <div style="cursor:pointer" :class="(point>=item.point)?`canredeem`:`cantredeem`" :disabled="isDisabled" @click="redeem(item)">
                                        <span style="font-size: 14px; font-weight: bold; color: #fff;">
                                        <img src="../assets/images/coin1.png" width="20">
                                        แลก {{numberWithCommas(item.point)}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
	      </main>
        <div style="position: fixed; left: 0; right: 0; bottom: 0; display: flex; z-index: 99; align-items: flex-end;" >
          <div style="flex: 1; background: linear-gradient( 180deg,#c1e9fe 10%,#56aff0); border-top-left-radius: 30px; display: flex; justify-content: center; height: 58px; box-shadow: 0 0 15px 0 rgb(0 0 0 / 30%); flex-direction: column; align-items: center;cursor:pointer;" @click="goto('website')">
            <img :src="logoLeft" alt="ทางเข้าเล่น" style="width: 60px; height: 58px; margin-top: -35px;margin-bottom: 8px">
            <div style="font-weight: 800; color: #fff; margin-top: -6px;font-family: Kanit,Helvetica Neue,Helvetica,Arial,sans-serif;">ทางเข้าเล่น</div>
          </div>
          <div style="flex: 1; background: linear-gradient( 180deg,#fedd5b 10%,#fe8c06); border-top-right-radius: 25px; border-top-left-radius: 25px; box-shadow: 0 0 15px 0 rgb(0 0 0 / 30%);z-index: 1;height: 80px; display: flex; flex-direction: column; align-items: center; justify-content: flex-end;cursor:pointer;" @click="goto('home')">
            <div>
                <img class="animate__animated animate__pulse animate__infinite" src="../assets/images/game-console.png" style="width: 105px; height: 105px; margin-bottom: 10px;" alt="เข้าเล่น Lobby">
            </div>
            <!-- <div style="font-weight: 800; color: #fff; margin-top: -13px;font-family: Kanit,Helvetica Neue,Helvetica,Arial,sans-serif;" class="p2">หน้าเกมส์</div> -->
          </div>
          <div style="flex: 1; background: linear-gradient( 180deg,#c1e9fe 10%,#56aff0); border-top-right-radius: 30px; display: flex; justify-content: center; height: 58px; justify-content: center; box-shadow: 0 0 15px 0 rgb(0 0 0 / 30%); flex-direction: column; align-items: center;cursor:pointer;" @click="goto('contact')">
            <img :src="logoRight" alt="ติดต่อ" style="width: 60px; height: 58px; margin-top: -35px;margin-bottom: 8px;margin-left: 18px;">
             <div style="font-weight: 800; color: #fff; margin-top: -6px;font-family: Kanit,Helvetica Neue,Helvetica,Arial,sans-serif;">ติดต่อ</div>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
import firebase, { database } from 'firebase/app'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/auth'
import _ from 'lodash'
// const axios = require("axios").default;
import moment from 'moment-timezone'
import router from './../router'
import Tabs from 'vue-tabs-with-active-line'
require('vue2-animate/dist/vue2-animate.min.css')
// @ is an alias to /src
export default {
  name: 'Huay',
  created () {
    //document.body.style.backgroundColor = '#000'
  },
  components: {
    Tabs,
  },
  mounted () {
    if (!firebase.auth().currentUser) {
      router.replace('/login')
    } else {
      this.getUserId()
    }
  },
  data () {
    return {
        tabs: [
            { title: 'เครดิต', value: 'tab1' },
            { title: 'ของรางวัล', value: 'tab2' },
        ],
        currentTab: 'tab1',
        logoLeft:'',
        logoRight:'',
        rewards_credit:[],
        rewards_reward:[],
        code:'',
        web:'',
        point:0,
        pictureUrl: '',
        isDisabled: false,
    }
  },
  methods: {
    logout() {
        this.$confirm(
          {
            message: `ต้องการล็อกเอาท์จากระบบ`,
            button: {
              yes: 'Yes',
              no: 'No'
            },
            callback: confirm => {
              if (confirm) {
                firebase.auth().signOut().then(()=>{
                  router.replace('/login')
                })
              }
            }
          }
        );
    },
    redeem2(item) {
      if(this.point >= item.point && !this.isDisabled) {
        this.$confirm(
        {
          title: 'Are you sure?',
          message: `‼️ ยืนยันต้องการแลก ${item.point} Points กับ ${item.credit} เครดิต?`,
          button: {
            no: 'No',
            yes: 'Yes'
          },
          /**
          * Callback Function
          * @param {Boolean} confirm 
          */
          callback: confirm => {
            if (confirm && this.user.key && !this.isDisabled) {
                this.isDisabled = true;
                this.loading = false;
                const point = this.point - item.point;
                this.point = point;
                firebase.database().ref(`/users/${this.$projectId}/${this.user.key}`).update({
                  point
                }).then(async()=>{
                    firebase.firestore().collection('transections').add({
                        code: this.user.code,
                        mobile: this.user.mobile,
                        createdate: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss'),
                        game: 'redeem',
                        game_name: item.name,
                        qty: item.credit,
                        type: item.type,
                        status: false,
                        web: this.$projectId
                    })
                    this.isDisabled = false;
                });
            }
          }
        })
      }
    },
    redeem: function(item) {
      if(this.point >= item.point && !this.isDisabled) {
        this.$confirm(
        {
          title: 'Are you sure?',
          message: `‼️ ยืนยันต้องการแลก ${item.point} Points กับ ${item.name}?`,
          button: {
            no: 'No',
            yes: 'Yes'
          },
          /**
          * Callback Function
          * @param {Boolean} confirm 
          */
          callback: confirm => {
            if (confirm && this.user.key && !this.isDisabled) {
                this.isDisabled = true
                this.loading = false
                const point = this.point - item.point
                this.point = point;
                firebase.database().ref(`/users/${this.$projectId}/${this.user.key}`).update({
                  point
                }).then(async()=>{
                    firebase.firestore().collection('transections').add({
                        code: this.user.code,
                        mobile: this.user.mobile,
                        createdate: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss'),
                        game: 'redeem',
                        game_name: item.name,
                        qty: 1,
                        type: item.type,
                        status: false,
                        web: this.$projectId
                    })
                    this.isDisabled = false;
                });
            }else{
              this.loading = false;
            }
          }
        }
      )
      }
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    handleClick(newTab) {
      this.currentTab = newTab
    },
    async getUserId () {
      this.web = (await firebase.database().ref(`/web/${this.$projectId}`).once('value')).val()
      if (this.web) {
        if(this.web.login_image){
          let width = screen.width;
          if(width <= 480 && this.web.login_image_mobile)
          this.pictureUrl = this.web.login_image_mobile;
          else
          this.pictureUrl = this.web.login_image;
        }else
          this.pictureUrl = require('../assets/images/2092839.jpg')
        if(this.web.img_left)
          this.logoLeft = this.web.img_left
        else
          this.logoLeft = require('../assets/images/casino.png')
        if(this.web.img_right)
          this.logoRight = this.web.img_right
        else
          this.logoRight = require('../assets/images/contact.png')
        const email = firebase.auth().currentUser.email.split('@')
        const users = (await firebase.database().ref(`/users/${this.$projectId}`).orderByChild('mobile').equalTo(email[0]).once('value')).val()
        this.user = users[Object.keys(users)[0]]
        this.user.key = Object.keys(users)[0]
        if (this.user) {
          this.point = this.user.point
          this.code = this.user.code
          const rewards = (await firebase.database().ref(`/rewards/${this.$projectId}`).once('value')).val()
          const rewards_credit = []
          const rewards_reward = []
          for(const x in rewards) {
              rewards[x].key = x
              if(rewards[x].type === 'credit') {
                  rewards_credit.push(rewards[x])
              }else{
                  rewards_reward.push(rewards[x])
              }
          }
          const rewards3 = _.orderBy(rewards_credit,['point'],['asc']);
          const rewards4 = _.orderBy(rewards_reward,['point'],['asc']);
          const rewardsdata = [];
          let i = 0;
          let page = 0;
          for(const x in rewards3){
            if(!rewardsdata[page]) {
              rewardsdata[page] = [];
            }
            rewardsdata[page].push(rewards3[x]);
            if(i%2 == 1){
              page++;
            }
            i++;
          }
          this.rewards_credit = rewardsdata;
          const rewardsdata2 = [];
          i = 0;
          page = 0;
          for(const x in rewards4){
            if(!rewardsdata2[page]) {
              rewardsdata2[page] = [];
            }
            rewardsdata2[page].push(rewards4[x]);
            if(i%2 == 1){
              page++;
            }
            i++;
          }
          this.rewards_reward = rewardsdata2;
        } else {
          router.replace('/login')
        }
        var styleElem = document.head.appendChild(document.createElement('style'))
        styleElem.innerHTML = `#contact_gl:before {background:#141313;}`
      } else {
        router.replace('/login')
      }
    },
    goto (type) {
      if (type === 'home') {
        // window.scrollTo({
        //   top: 0,
        //   left: 0,
        //   behavior: 'smooth'
        // });
        this.$router.push('/games').catch(() => {})
      } else if (type === 'website') {
        window.location = this.web.link
        // this.gotoline()
      } else if (type === 'contact') {
        window.location = this.web.link_line
        // this.gotoline()
      }
    }
  }
}
</script>
<style scoped>
.center {
    position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#contact_gl {
  min-height: 600px;
  color: #333;
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
	/*background: -moz-radial-gradient(center, ellipse cover, #f7f7f7 0%, #d1d1d1 100%);
	background: -webkit-radial-gradient(center, ellipse cover, #f7f7f7 0%,#d1d1d1 100%);
	background: radial-gradient(ellipse at center, #f7f7f7 0%,#d1d1d1 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f7f7', endColorstr='#d1d1d1',GradientType=1 );*/
}
#contact_gl::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
}
 .p2 {
	 letter-spacing: 0;
	 text-shadow: 0 0 80px rgba(255, 255, 255, .5);
	/* Clip Background Image */
	 background: url(https://i.ibb.co/RDTnNrT/animated-text-fill.png) repeat-y;
	 -webkit-background-clip: text;
	 background-clip: text;
	/* Animate Background Image */
	 -webkit-text-fill-color: transparent;
	 -webkit-animation: aitf 80s linear infinite;
	/* Activate hardware acceleration for smoother animations */
	 -webkit-transform: translate3d(0, 0, 0);
	 -webkit-backface-visibility: hidden;
}
.canredeem {
  border-radius: 7px; 
  margin-top: 10px; 
  height: 32px; 
  background: #EE4D2D; 
  align-items: center; 
  justify-content: center; 
  display: flex;
}
.cantredeem {
  border-radius: 7px; 
  margin-top: 10px; 
  height: 32px; 
  background: #a5a4a4; 
  align-items: center; 
  justify-content: center; 
  display: flex;
}
button.tabs__item .tabs__item_active .default-tabs__item_active .default-tabs__item {
  justify-content: center !important;
  flex: 1 !important;
  display: flex !important;
}
.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}
</style>
<style lang="scss">
.default-tabs {
  position: relative;
  margin: 0 auto;
  display: flex;
  &__item {
    justify-content: center;
    flex: 1;
    display: flex;
    //display: inline-block;
    margin: 0 5px;
    padding: 10px;
    padding-bottom: 8px;
    padding-top: 15px;
    font-size: 16px;
    letter-spacing: 0.8px;
    color: #fff;
    text-decoration: none;
    border: none;
    background-color: transparent;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    transition: all 0.25s;
    &_active {
      color: #E95454;
    }
    &:hover {
      border-bottom: 2px solid #E95454;
      color: #E95454;
    }
    &:focus {
      outline: none;
      border-bottom: 2px solid #E95454;
      color: #E95454;
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &__active-line {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    background-color: #E95454;
    transition: transform 0.4s ease, width 0.4s ease;
  }
}
.boxlogin {
    background: #fff;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    box-shadow: 0 0 40px #000;
    color: #8b8c8d;
    font-size: 0;
}
.boxlogin .box-part {
    display: inline-block;
    position: relative;
    vertical-align: top;
    height: 100%;
    width: 100%;
}
.boxlogin, .box .box-part {
    box-sizing: border-box;
}
.boxlogin .partition {
    width: 100%;
    height: 100%;
}
.boxlogin .partition .partition-title {
    box-sizing: border-box;
    padding: 30px;
    width: 100%;
    text-align: center;
    letter-spacing: 1px;
    font-size: 20px;
    font-weight: 300;
}
.boxlogin .partition .partition-form {
    padding: 0 20px;
    box-sizing: border-box;
}
.boxlogin .button-set {
    margin-bottom: 8px;
}
.boxlogin .github-btn {
    border-color: #dba226;
    color: #dba226;
}
.boxlogin .large-btn {
    width: 100%;
    background: #fff;
}
.boxlogin button {
    background: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 10px;
    letter-spacing: 1px;
    font-family: Open Sans,sans-serif;
    font-weight: 400;
    min-width: 140px;
    margin-top: 8px;
    color: #8b8c8d;
    cursor: pointer;
    border: 1px solid #dddedf;
    text-transform: uppercase;
    transition: all .1s;
    font-size: 10px;
    outline: none;
}
</style>